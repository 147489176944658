import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/common/seo";
import { Studio } from "../components/pageLayout/Studio";
import { toPlainText } from "../utils/common";
import { trimLongString } from "../utils/common";

export const query = graphql`
  query StudioTemplateQuery($id: String!) {
    studio: sanityStudio(id: { eq: $id }) {
      id
      title
      slug {
        current
      }
      publishedAt(formatString: "Do MMMM, YYYY")
      categories {
        _id
        title
      }
      mainImage {
        asset {
          id
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          url
        }
      }
      _rawDescription
      description {
        _type
        _key
        children {
          _key
          _type
          marks
          text
        }
      }
      media {
        ... on SanityImageItem {
          alt
          caption
          asset {
            gatsbyImageData
          }
        }
        ... on SanityVideoItem {
          url
          caption
        }
      }
    }
  }
`;

const StudioTemplate = ({ data }) => {
  const studio = data.studio;
  const seoDescription = studio.description
    ? trimLongString(toPlainText(studio.description), 300)
    : "";
  const seoImage = studio.mainImage?.asset?.url;

  return (
    <>
      <Seo title={studio.title} image={seoImage} description={seoDescription} />
      <Studio studio={studio} />
    </>
  );
};

export default StudioTemplate;
