import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Hero } from "../../common/Hero";
import { BlockContent } from "../../common/BlockContent";
import { ImageMediaGrid } from "../../common/ImageMediaGrid";

import * as styles from "./Studio.module.scss";

const Studio = ({ studio }) => {
  return (
    <article>
      <Hero image={studio.mainImage.asset.gatsbyImageData} alt="" />
      <h1>{studio.title}</h1>
      <div className={styles.info}>
        <section className={styles.details}>{studio.publishedAt}</section>
        <section className={styles.description}>
          <BlockContent blocks={studio._rawDescription || []} />
        </section>
      </div>

      <section>
        <ImageMediaGrid items={studio.media} defaultAlt={studio.title} />
      </section>
    </article>
  );
};

export { Studio };
